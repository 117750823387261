<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-1"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <v-row no-gutters class="pt-2">
          <v-col cols="5">
            <v-autocomplete
              v-model="processId"
              outlined
              dense
              :items="processes"
              :loading="isLoadingProcesses"
              :search-input.sync="searchProcesses"
              hide-no-data
              item-text="name"
              item-value="id"
              class="pl-2"
              :label="$lang.labels.process"
              :placeholder="$lang.actions.startTyping"
              prepend-inner-icon="mdi-cloud-search-outline"
              style="min-width: 350px"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2" class="pl-2">
            <v-datetime-picker
              v-model="dateFrom"
              :label="$lang.labels.from"
              no-title
              :text-field-props="{ outlined: true, dense: true }"
            >
              <template v-slot:dateIcon="{}">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:timeIcon="{}">
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>
          <v-col cols="2" class="pl-2">
            <v-datetime-picker
              v-model="dateTill"
              :label="$lang.labels.to"
              no-title
              :text-field-props="{ outlined: true, dense: true }"
            >
              <template v-slot:dateIcon="{}">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:timeIcon="{}">
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>
          <v-col cols="2">
            <v-btn
              text
              color="primary"
              class="ml-2"
              @click="sendQuery()"
            ><v-icon>mdi-refresh</v-icon></v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.startedOn="{ item }">
        <div>{{ item.startedOn | formatDateTimePrecise }}</div>
      </template>

      <template v-slot:item.finishedOn="{ item }">
        <div>{{ item.finishedOn | formatDateTimePrecise }}</div>
      </template>

      <template v-slot:item.eventId="{ item }">
        <v-btn
          text
          small
          color="primary"
          @click="showEventDetails(item.eventId)"
        >{{ item.eventId }}</v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-inline-flex">
          <v-btn color="primary" class="button-default-width" @click="goToLogsStep(item.processId, item.eventId, dateFrom, dateTill)">{{ $lang.labels.steps }}</v-btn>
          <v-btn
            color="info"
            class="button-default-width ml-2"
            @click="goToProcessDebug(item.processId, item.eventId)"
          >
            {{ $lang.labels.debug }}
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-if="showDetails" v-model="showDetails" max-width="71%" style="min-height: 80vh; max-height: 80vh">
      <log-cache
        :event-id="String(eventId)"
        :data="selectedEventData"
        @closeDialog="eventId = ''; selectedEventData = null; showDetails = false"
      ></log-cache>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getProcessesUsingGET as getProcesses,
  getEventByIdUsingGET as getEvent,
  getProcessByIdUsingGET as getProcess
} from '@/utils/api'
import LogCache from '../../components/ui/modals/LogCacheEvent'
import { mapState } from 'vuex'
import { bus } from '@/main'
import { format } from 'date-fns'
import { eventId } from '../../lang/de/labels'

export default {
  components: {
    LogCache
  },
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      showDetails: false,
      selectedEventData: null,
      eventId: '',
      isLoadingProcesses: false,
      showTypeSelector: false,
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.instanceId,
          align: 'start',
          sortable: true,
          value: 'instanceId'
        },
        { text: this.$lang.labels.startedOn, value: 'startedOn' },
        { text: this.$lang.labels.finishedOn, value: 'finishedOn' },
        { text: this.$lang.labels.eventId, value: 'eventId' },
        { text: this.$lang.labels.threadId, value: 'threadId', sortable: false },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      processes: [],
      searchProcesses: '',
      dateFrom: new Date(),
      dateTill: new Date(Date.now() + ( 3600 * 1000 * 24)),
      processId: ''
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userSettings']),
    computedDateFromFormatted () {
      return this.$options.filters.formatDate(this.dateFrom)
    },
    computedDateTillFormatted () {
      return this.$options.filters.formatDate(this.dateTill)
    }
  },
  watch: {
    searchProcesses: {
      handler(val) {
        if (val && val.length > 1) this.searchProcessesFunction(val)
      }
    },
    options: {
      handler () {
        this.sendQuery(false)
      },
      deep: true
    },
    processId: {
      handler () {
        this.sendQuery()
      }
    },
    dateFrom: {
      handler () {
        this.sendQuery()
      }
    },
    dateTill: {
      handler () {
        this.sendQuery()
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.sendQuery()
    })
  },
  created() {
    this.dateFrom.setHours(0,0,0,0)
    this.dateTill.setHours(23,59,59,999)
    if (this.$route.query && this.$route.query.processId && this.$route.query.dateFrom && this.$route.query.dateTill) {
      this.dateFrom = new Date(this.$route.query.dateFrom)
      this.dateFrom.setHours(0,0,0,0)
      this.dateTill = new Date(this.$route.query.dateTill)
      this.dateTill.setHours(23,59,59,999)
      this.processId = this.$route.query.processId
      this.loadPreFill()

      return
    }

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id' })
    }

    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.processLogList) {
        this.dateFrom = new Date(preFill.processLogList.dateFrom)
        this.dateTill = new Date(preFill.processLogList.dateTill)
        this.processId = preFill.processLogList.processId
        this.loadPreFill()
      } else {
        this.lock = false
      }
    } else {
      this.lock = false
    }
  },
  methods: {
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.processLogList) preFill.processLogList = {
          dateFrom: '',
          dateTill: '',
          processId: ''
        }

        preFill.processLogList.dateFrom = this.dateFrom
        preFill.processLogList.dateTill = this.dateTill
        preFill.processLogList.processId = this.processId

        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    loadPreFill() {
      this.isLoadingProcesses = true
      getProcess({
        id : this.processId
      })
        .then((res) => {
          this.processId = ''
          this.processes = [res.data.data]
          this.isLoadingProcesses = false
          this.searchProcesses = res.data.data.name
          this.processId = res.data.data.id
          setTimeout(() => {
            this.lock = false
            this.savePreFill()
            this.sendQuery()
          }, 50)
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.err = err
        })
    },
    sendQuery(resetPage = true) {
      if (this.dateFrom && this.dateTill && this.processId && !this.lock) {
        this.$emit('fetchLogs', { options: this.options, dateFrom: this.dateFrom, dateTill: this.dateTill, processId: this.processId, resetPage })
        this.savePreFill()
        if (resetPage) this.options.page = 1
      }
    },
    goToLogsStep(processId, eventId, dateFrom, dateTill) {
      this.$router.push({ name: 'logs-steps', query: { processId, eventId, dateFrom: format(dateFrom, 'yyyy-MM-dd'), dateTill: format(dateTill, 'yyyy-MM-dd') } })
    },
    searchProcessesFunction(val = '') {
      this.isLoadingProcesses = true
      const obj = {}

      if (val && val.length > 1 ) obj.name = val
      getProcesses(obj)
        .then((res) => {
          this.processes = res.data.data.items
          this.isLoadingProcesses = false
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.err = err
        })
    },
    showEventDetails(id) {
      getEvent({
        id
      })
        .then((res) => {
          this.selectedEventData = res.data.data
          this.eventId = id
          this.showDetails = true
        })
        .catch((err) => {
          this.err = err
        })
    },
    goToProcessDebug(processId, eventId) {
      const routeData = this.$router.resolve({
        name: 'processEdit',
        params: { id: processId },
        query: {
          eventId
        }
      })

      window.open(routeData.href, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.clickable {
  cursor: pointer;
  text-decoration: underline;
  color: var(--v-primary-base);;
}
</style>
